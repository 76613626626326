export const DEFAULT_SETTING_OBJECT = {
  reference: {},
  car_classes: [],
  active: false,
  days_options: {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    holiday: false,
    tomorrow_holiday: false
  },
  inventory: {}
}

export const TOGGLE_COLOR = "#2e9fad"

export const INVENTORY_FIELDS = ["shop_inventory_limit", "sale_stop", "shared_inventory_limit"]
