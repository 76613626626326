import StoreItemsModule from "@/config/store/StoreItemsModule"

import extractActions from "./actions"
import extractMutations from "./mutations"

const BASE_URI = "/inventory_settings"

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  presetActions: ["fetch"],
  withFilters: {
    search_value: ""
  }
})

store.mergeActions(extractActions(BASE_URI))
store.mergeMutations(extractMutations())

export default store
