import { groupBy, mapValues, map, keys, transform, reduce, flatMap, concat, uniq, filter, isNull } from "lodash-es"

export const getOrderedCarClassIds = ({ inventory }) => {
  const carClassIds = keys(inventory)

  return carClassIds.sort((a, b) => a - b)
}

export const settingIds = ({ settings }) => {
  return map(settings, "id")
}

export const totalSettings = ({ initialSettings, settings, settingIds }) => {
  const filteredSettings = filter(initialSettings, ({ id }) => !settingIds.includes(id))
  return [...filteredSettings, ...settings]
}

export const collapsedSettingsGrouped = ({ totalSettings, initialSetting }) => {
  const settings = isNull(initialSetting) ? totalSettings : filter(totalSettings, ({ id }) => id !== initialSetting.id)
  return groupBy(settings, "reference.id")
}

export const selectedDaysOptions = ({ totalSettings: settings }) => {
  const groupedByShops = groupBy(settings, "reference.id")

  const carClassIdsDaysOptionsByShops = mapValues(groupedByShops, settings => {
    return getCarClassIdsDaysOptions(settings)
  })

  return reduce(
    settings,
    (obj, setting) => {
      const { id: referenceId } = setting.reference
      obj[referenceId] = obj[referenceId] || {}
      const currentSettingCarClassIds = getOrderedCarClassIds(setting)

      obj[referenceId][currentSettingCarClassIds] = getSelectedDaysOptions(
        carClassIdsDaysOptionsByShops[referenceId],
        currentSettingCarClassIds
      )
      return obj
    },
    {}
  )
}

const getCarClassIdsDaysOptions = settings => {
  return map(settings, ({ days_options, ...setting }) => {
    const orderedCarClassIds = getOrderedCarClassIds(setting)

    return {
      carClassIds: orderedCarClassIds,
      daysOptions: getKeysByValues(days_options)
    }
  })
}

export const getKeysByValues = collection => {
  return transform(
    collection,
    (obj, value, key) => {
      if (value) obj.push(key)
      return obj
    },
    []
  )
}

const getSelectedDaysOptions = (carClassIdsDaysOptions, currentCarClassIds) => {
  const selectedOptions = flatMap(currentCarClassIds, carClassId => {
    return reduce(
      carClassIdsDaysOptions,
      (obj, { carClassIds, daysOptions }) => {
        if (carClassIds.includes(carClassId)) obj = concat(obj, daysOptions)

        return obj
      },
      []
    )
  })

  return uniq(selectedOptions)
}
