import { api, i18n } from "@/config"
import { showToast } from "@/helpers/toasts"
import { map } from "lodash-es"

const buildParams = ({ reference, active, inventory, days_options }) => ({
  reference_id: reference.id,
  // settings: [{ car_class_id: 1, shop_inventory_limit: 5, shared_inventory_limit: null, sale_stop: false }, ...]
  settings: map(inventory, (data, car_class_id) => ({ car_class_id, ...data })),
  days_options,
  active
})

export default baseURI => ({
  CREATE_ITEM: async ({ commit }, item) => {
    try {
      const params = buildParams(item)
      const response = await api.post(baseURI, params)

      commit("SET_ITEMS", response.data.data)
    } catch ({ response: { status } }) {
      if (status === 422) {
        showToast({ text: i18n.t("activerecord.errors.models.inventory_setting.could_not_create") })
      }
    }
  },

  UPDATE_ITEM: async ({ commit }, item) => {
    try {
      const params = buildParams(item)
      const response = await api.put(`${baseURI}/${item.id}`, params)

      commit("SET_ITEMS", response.data.data)
    } catch ({ response: { status } }) {
      if (status === 422) {
        showToast({ text: i18n.t("activerecord.errors.models.inventory_setting.could_not_update") })
      }
    }
  },

  DELETE_ITEM: async ({ commit }, { item }) => {
    try {
      const response = await api.delete(`${baseURI}/${item.id}`)

      commit("SET_ITEMS", response.data.data)
    } catch ({ response: { status } }) {
      if (status === 404) {
        showToast({ text: i18n.t("activerecord.errors.models.inventory_setting.not_found") })
      }
    }
  },

  DELETE_UNSAVED_SETTING: async ({ commit }, { index }) => {
    commit("DELETE_ITEM_BY_INDEX", index)
  }
})
